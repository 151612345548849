<script setup lang="ts">
interface Props {
  buttonDisabled?: boolean;
  hasWideWrapper?: boolean;
  isLoading?: boolean;
  buttonText: string;
  withMobileFallbackButton?: boolean;
  showLoginHint?: boolean;
  showButton?: boolean;
}

const { t } = useNuxtApp().$i18n;
const router = useRouter();
const checkoutUrl = router.resolve({ path: "/onboarding" }).href;

const props = withDefaults(defineProps<Props>(), {
  buttonDisabled: false,
  hasWideWrapper: false,
  isLoading: false,
  showLoginHint: false,
  buttonText: "",
  withMobileFallbackButton: false,
  showButton: true,
});

const isMobileFallbackButtonShown = ref(false);
const additionalButton = ref<HTMLDivElement | null>(null);

interface Emits {
  (e: "buttonClick"): void;
}

const emit = defineEmits<Emits>();

function onAdditionalButtonClick() {
  if (props.buttonDisabled || !isMobileFallbackButtonShown.value) {
    return;
  }

  emit("buttonClick");
}

function onVisualViewportResize() {
  setTimeout(() => {
    isMobileFallbackButtonShown.value = (window?.visualViewport?.height ?? 550) < 550;

    if (isMobileFallbackButtonShown.value) {
      additionalButton.value?.scrollIntoView({
        behavior: "smooth",
        block: navigator.userAgent.toLowerCase().includes("android") ? "end" : "start",
      });
    }
  }, 100);
}

onMounted(() => {
  if (!props.withMobileFallbackButton) {
    return;
  }

  if (
    navigator.userAgent.includes("Mobile") &&
    !navigator.userAgent.includes("Instagram") &&
    !navigator.userAgent.includes("Firefox")
  ) {
    window?.visualViewport?.addEventListener("resize", onVisualViewportResize);
  }
});

onUnmounted(() => window?.visualViewport?.removeEventListener("resize", onVisualViewportResize));
</script>

<template>
  <div class="flex flex-col justify-between md:justify-start md:gap-5">
    <div>
      <slot />

      <div
        v-if="props.withMobileFallbackButton && isMobileFallbackButtonShown && showButton"
        ref="additionalButton"
        class="mt-4 flex justify-center"
      >
        <div
          class="m-auto mb-4 min-w-full rounded-full border-0 bg-yz-blue-600 px-8 py-4 text-center font-medium text-white shadow-md transition-opacity duration-300"
          :class="{
            'cursor-pointer opacity-100': isMobileFallbackButtonShown,
            'opacity-0': !isMobileFallbackButtonShown,
          }"
          @click="onAdditionalButtonClick"
        >
          <span>{{ buttonText }}</span>
        </div>
      </div>
    </div>

    <div class="sticky bottom-0 grid justify-items-center bg-gradient-to-t from-neutral-50 from-80%">
      <div
        v-if="!isMobileFallbackButtonShown && showButton"
        class="sticky bottom-0 z-10 block w-full self-center py-4 transition-opacity duration-300 md:mx-auto md:max-w-[41rem]"
      >
        <div class="container justify-center md:flex" :class="{ 'px-bs-container': hasWideWrapper }">
          <div class="md:w-7/12">
            <button
              id="stickyButton"
              :disabled="buttonDisabled"
              class="w-full rounded-full bg-yz-blue-600 px-3 py-4 font-medium text-white shadow-md transition hover:opacity-90"
              @click="$emit('buttonClick')"
            >
              <span
                v-if="isLoading"
                class="inline-block size-4 animate-spin rounded-full border-[0.2rem] border-white border-r-transparent align-[-0.125em]"
                aria-hidden="true"
              />
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="showLoginHint"
        class="text-center text-sm"
        v-html="t('app.prosignup.pro.label.no_coupon', [checkoutUrl])"
      ></div>
    </div>
  </div>
</template>
